class NavScroll {
    constructor (selector) {
      this.$element = document.querySelector(selector)
      this.navActiveClass =  this.$element.dataset.activeClass.split(" ") //amplify-nav
      this.navInitClass =  this.$element.dataset.initClass.split(" ") //'is-transparent'
  
      document.addEventListener('DOMContentLoaded', () => {
        if (typeof this.callback === 'function') {
          this.callback()
        }
      })
    }
  
    onReady (callback) {
      this.callback = callback
    }
    init () {
      let navbar = this
  
      if (window.scrollY > 1) {
        for (let index = 0; index < navbar.navInitClass.length; ++index) {
          navbar.$element.classList.remove(navbar.navInitClass[index])
        }
        for (let index = 0; index < navbar.navActiveClass.length; ++index) {
          navbar.$element.classList.add(navbar.navActiveClass[index])
        }
      }
  
      window.addEventListener('scroll', function () {
        let scroll = window.scrollY
        if (scroll > 1) {
          for (let index = 0; index < navbar.navInitClass.length; ++index) {
            navbar.$element.classList.remove(navbar.navInitClass[index])
          }
          for (let index = 0; index < navbar.navActiveClass.length; ++index) {
            navbar.$element.classList.add(navbar.navActiveClass[index])
          }
        } else {
          navbar.reset()
        }
      })
    }
    reset () {
      for (let index = 0; index < this.navActiveClass.length; ++index) {
        this.$element.classList.remove(this.navActiveClass[index])
      }
      for (let index = 0; index < this.navInitClass.length; ++index) {
        this.$element.classList.add(this.navInitClass[index])
      }
    }
  }
  
  export default NavScroll
  