class MobileMenu {
    constructor (selector) {
      let menu = this
      this.$rootEl = document.documentElement
      this.$element = document.querySelector(selector)
      this.navActiveClass = 'is-active'


      this.naviInitClass =  this.$element.dataset.initClass.split(" ") 
      this.naviActiveClass =  this.$element.dataset.activeClass.split(" ")

      this.clippedClass = 'is-clipped'
      this.$navbarBurgers = Array.prototype.slice.call(this.$element.querySelectorAll('.navbar-burger'), 0)
      this.$navbarMenu = this.$element.querySelector('#navbarMenu')
      this.$dropdownLinks = Array.prototype.slice.call(this.$navbarMenu.querySelectorAll('.has-dropdown'),0)
  
      document.addEventListener('DOMContentLoaded', () => {
        if (typeof this.callback === 'function') {
          this.callback()
        }
      })

      document.addEventListener("click", function(event) {

        if (event.target.closest(".has-dropdown")) {
          return
        }      

        menu.resetDropdowns()
      })
    }
  
    onReady (callback) {
      this.callback = callback
    }

    init () {
      if (this.$navbarBurgers.length > 0) {
        this.$navbarBurgers.forEach(el => {

          el.addEventListener('click', () => {
            
            for (let index = 0; index < this.naviInitClass.length; ++index) {
              this.$element.classList.toggle(this.naviInitClass[index])
            }

            for (let index = 0; index < this.naviActiveClass.length; ++index) {
              this.$element.classList.toggle(this.naviActiveClass[index])
            }

            const target = el.dataset.target
            const $target = document.getElementById(target)
            this.$rootEl.classList.toggle(this.clippedClass);
            el.classList.toggle(this.navActiveClass)
            $target.classList.toggle(this.navActiveClass) 
          })

        })
      }

      if (this.$dropdownLinks.length > 0) {
        this.$dropdownLinks.forEach(el => {
            el.addEventListener('click', (event) => {
              
              if(event.target.classList.contains('navbar-link')){
                event.preventDefault()
                for (var j = 0; j < this.$dropdownLinks.length; j++) {
                  if(el.id !== this.$dropdownLinks[j].id ){
                    this.$dropdownLinks[j].classList.remove(this.navActiveClass)
                  }
                }
                el.classList.toggle(this.navActiveClass)
              }
            })
          })
      }


      window.addEventListener('resize', () => {
        this.resetNavs()
      })
    }

    resetDropdowns () {
      for (var j = 0; j < this.$dropdownLinks.length; j++) {
        this.$dropdownLinks[j].classList.remove(this.navActiveClass)
      }
    }

    resetNavs () {
      for (var i = 0; i < this.$navbarBurgers.length; i++) {
        this.$navbarBurgers[i].classList.remove(this.navActiveClass)
      }

      this.$navbarMenu.classList.remove(this.navActiveClass)
      this.$rootEl.classList.remove(this.clippedClass);

      for (let index = 0; index < this.naviActiveClass.length; ++index) {
        this.$element.classList.remove(this.naviActiveClass[index])
      }

      for (let index = 0; index < this.naviInitClass.length; ++index) {
        this.$element.classList.add(this.naviInitClass[index])
      }
  
      this.resetDropdowns()

    }
  }
  
  export default MobileMenu
  